<template>
    <div>
        <Dialog v-model:visible="visible" modal :header="dialogHeader" class="dialog" :footer="dialogFooter" :style="{ 'max-width': modalMaxWidth }">
            <div v-if="currentStep === 1" class="step">
                <img src="./step1.svg" alt="Imagem 1" class="step-image" />
                <p>Bem-vindo(a) à <span style="font-weight: bold">nova fase</span> da nossa marca!</p>
                <p>Estamos evoluindo para oferecer uma experiência ainda melhor para você.</p>
            </div>
            <div v-else-if="currentStep === 2" class="step">
                <img src="./step2.svg" alt="Imagem 2" class="step-image" />
                <p>A <span style="text-decoration: line-through">PIOM</span> agora é <span style="font-weight: bold; color: #364de7">DUON!</span></p>
                <p>
                    Estamos de <span style="font-weight: bold">cara nova</span>, refletindo nossa <span style="font-weight: bold">evolução</span> e
                    <span style="font-weight: bold">compromisso</span> com a excelência.
                </p>
            </div>
            <div v-else-if="currentStep === 3" class="step">
                <img src="./419.png" alt="Imagem 3" class="step-image" />
                <div class="step-content">
                    <div class="step-item">
                        <p style="font-weight: bold">Moderna</p>
                        <p>Moderna Acompanha as melhores práticas em gestão de RH.</p>
                    </div>
                    <div class="step-item">
                        <p style="font-weight: bold">Acessível</p>
                        <p>Acessível Design claro e amigável, que simplifica suas tarefas.</p>
                    </div>
                    <div class="step-item">
                        <p style="font-weight: bold">Intuitiva</p>
                        <p>Intuitiva Fácil de usar, para uma experiência mais eficiente.</p>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <Button v-if="currentStep > 1" @click="prevStep" label="Anterior" class="p-button-text p-button-left" />
                    <Button v-if="currentStep < 3" @click="nextStep" label="Próximo" class="p-button-next p-button-right" />
                    <div class="p-button-right">
                        <Button v-if="currentStep === 3" @click="closeDialog" label="Ficar na Duon" class="p-button-text p-button-ficar-duon" />
                        <Button v-if="currentStep === 3" @click="acessarDuon" label="Acessar" class="p-button-close" />
                    </div>
                </div>
            </template>
        </Dialog>
    </div>
</template>


<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

export default {
    components: {
        Dialog,
        Button
    },
    data() {
        return {
            visible: true,
            currentStep: 1,
            modalMaxWidth: '600px',
            dialogHeader: 'Novidades!'
        };
    },
    methods: {
        nextStep() {
            if (this.currentStep < 3) {
                this.currentStep++;
                this.updateDialogHeader();
            }
        },
        prevStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
                this.updateDialogHeader();
            }
        },
        updateDialogHeader() {
            switch (this.currentStep) {
                case 1:
                    this.dialogHeader = 'Novidades!';
                    break;
                case 2:
                    this.dialogHeader = 'Mudança de marca';
                    break;
                case 3:
                    this.dialogHeader = 'Nova plataforma de RH';
                    break;
                default:
                    this.dialogHeader = 'Novidades!';
            }
        },
        acessarDuon() {
            window.open('https://plataformarh.duon.io/', '_blank');
        },
        closeDialog() {
            this.visible = false;
        }
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.dialog-footer {
    display: flex;
    justify-content: space-between;
}

.dialog {
    border-radius: 20px !important;
    overflow: hidden;
}

.step {
    text-align: left;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    color: #1e293b;
}

.step-title {
    font-family: var(--FontfamilyPoppins);
    font-size: var(--Tamanhodefontelg-1125rem18px);
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    margin-bottom: 10px;
}

.step-item {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
}

.step-item:last-child {
    margin-right: 0;
}

.step-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
    margin-bottom: 1rem;
}

.p-button-text {
    color: #007ad9;
    width: 200px;
    border-radius: 28px;
}

.p-button-next,
.p-button-close {
    background-color: #364de7;
    color: white;
    width: 200px;
    border-radius: 28px;
}

.p-button-ficar-duon {
    background-color: #6373e0;
    color: white;
    width: 200px;
    border-radius: 28px;
}

.p-button-left {
    margin-right: auto;
}

.p-button-right {
    margin-left: auto;
}

.p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}
</style>
