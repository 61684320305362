<template>
    <div>
        <div>
            <CarrocelNovidade />
        </div>
        <div class="layout-content">
            <Message severity="info">
                <div class="" data-v-1b971b90="">
                    <h5 data-v-1b971b90="">
                        Bem-vindo(a) <b>{{ profile.name }}</b> a Duon!
                    </h5>
                    <p data-v-1b971b90="">Através do menu lateral é possível utilizar as funcionalidades do aplicativo. Aproveite!</p>
                </div>
            </Message>
            <Message v-if="agendamentosPendentesCliente" severity="warn" @click="showGestaoMedica()" style="cursor: pointer" :closable="false">
                <div>
                    <h5>ATENÇÃO! Você possui {{ agendamentosPendentesCliente }} pendência(s) não resolvida(s).</h5>
                    <p>Clique aqui para resolvê-la(s).</p>
                </div>
            </Message>
            <div class="grid timeline layout-dashboard" data-v-72042794="">
                <div class="col-12 md:col-6 lg:col-3" data-v-72042794="">
                    <div class="overview-box card" data-v-72042794="">
                        <div class="overview-box-value" data-v-72042794="">{{ agendamentosEmAberto }}</div>
                        <div class="overview-box-title" data-v-72042794="">Em aberto</div>
                        <img src="layout/images/dashboard/tela-inicial/graph-agendamentos-aberto.svg" alt="roma" data-v-72042794="" />
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-3" data-v-72042794="">
                    <div class="overview-box card" data-v-72042794="">
                        <div class="overview-box-value" data-v-72042794="">{{ agendamentosConcluidos }}</div>
                        <div class="overview-box-title" data-v-72042794="">Concluídos</div>
                        <img src="layout/images/dashboard/tela-inicial/graph-agendamentos-concluidos.svg" alt="roma" data-v-72042794="" />
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-3" data-v-72042794="">
                    <div class="overview-box card" data-v-72042794="">
                        <div class="overview-box-value" data-v-72042794="">{{ funcionarios }}</div>
                        <div class="overview-box-title" data-v-72042794="">Funcionários</div>
                        <img src="layout/images/dashboard/tela-inicial/graph-funcionarios.svg" alt="roma" data-v-72042794="" />
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-3" data-v-72042794="">
                    <div class="overview-box card" data-v-72042794="">
                        <div class="overview-box-value" data-v-72042794="">{{ usuariosCliente }}</div>
                        <div class="overview-box-title" data-v-72042794="">Usuários</div>
                        <img src="layout/images/dashboard/tela-inicial/graph-usuarios.svg" alt="roma" data-v-72042794="" />
                    </div>
                </div>
                <div class="col-12 md:col-8 lg:col-8" data-v-72042794="">
                    <div class="card card-w-title" data-v-72042794="">
                        <h5 data-v-72042794="">Gestão Médica</h5>
                        <div class="p-chart" data-v-72042794="">
                            <Chart
                                class=""
                                type="bar"
                                :data="chartAgendamentos"
                                :options="{
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    }
                                }"
                            />
                        </div>
                    </div>
                    <div class="col-12 xl:col-6">
                        <div class="expenses card-w-title"></div>
                    </div>
                </div>
                <div class="col-12 md:col-4 lg:col-4" data-v-72042794="">
                    <div class="card card-w-title" style="height: 340px" data-v-72042794="">
                        <h5 data-v-72042794="">Atualizações</h5>
                        <ul data-v-72042794="">
                            <li data-v-72042794="">
                                <i class="pi pi-check-circle" data-v-72042794=""></i
                                ><span class="timeline-event-title" data-v-72042794="">Versão 1.0</span
                                ><span class="timeline-event-time" data-v-72042794="">01/09/2022</span>
                                <p data-v-72042794="">Lançamento da versão 1.0</p>
                                <Divider></Divider>
                            </li>
                        </ul>
                    </div>
                </div>
                <Divider align="center">
                    <span class="p-tag-outline">Dashboard</span>
                </Divider>
                <div class="centralizar col-12 md:col-4 lg:col-4" data-v-72042794="">
                    <div class="card card-w-title" data-v-72042794="">
                        <h5 data-v-72042794="">Distribuição por sexo</h5>
                        <div class="p-chart" data-v-72042794="">
                            <Chart class="" type="doughnut" :data="chartSexo" />
                        </div>
                    </div>
                </div>
                <div class="centralizar col-12 md:col-4 lg:col-4" data-v-72042794="">
                    <div class="card card-w-title" data-v-72042794="">
                        <h5 data-v-72042794="">Distribuição por situação</h5>
                        <div class="p-chart" data-v-72042794="">
                            <Chart class="" type="doughnut" :data="chartSituacao" />
                        </div>
                    </div>
                </div>
                <div class="centralizar col-12 md:col-4 lg:col-4" data-v-72042794="">
                    <div class="card card-w-title" data-v-72042794="">
                        <h5 data-v-72042794="">Distribuição por idade</h5>
                        <div class="p-chart" data-v-72042794="">
                            <Chart class="" type="doughnut" :data="chartFaixaEtaria" />
                        </div>
                    </div>
                </div>
            </div>
            <Divider align="center">
                <span class="p-tag-outline">Gerenciais</span>
            </Divider>
            <div class="col-12 xl:col-12" data-v-72042794="">
                <div class="expenses card-w-title" data-v-72042794="">
                    <div class="grid layout-dashboard" data-v-72042794="">
                        <div class="col-12 md:col-6 lg:col-3" data-v-72042794="">
                            <div class="overview-box card" data-v-72042794="">
                                <div class="overview-box-value" data-v-72042794="">{{ clientes }}</div>
                                <div class="overview-box-title" data-v-72042794="">Clientes</div>
                                <img src="layout/images/dashboard/tela-inicial/graph-clientes.svg" alt="roma" data-v-72042794="" />
                            </div>
                        </div>
                        <div class="col-12 md:col-6 lg:col-3" data-v-72042794="">
                            <div class="overview-box card" data-v-72042794="">
                                <div class="overview-box-value" data-v-72042794="">{{ unidades }}</div>
                                <div class="overview-box-title" data-v-72042794="">Unidades</div>
                                <img src="layout/images/dashboard/tela-inicial/graph-unidades.svg" alt="roma" data-v-72042794="" />
                            </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-3" data-v-72042794="">
                            <div class="overview-box card" data-v-72042794="">
                                <div class="overview-box-value" data-v-72042794="">{{ setores }}</div>
                                <div class="overview-box-title" data-v-72042794="">Setores</div>
                                <img src="layout/images/dashboard/tela-inicial/graph-setores.svg" alt="roma" data-v-72042794="" />
                            </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-3" data-v-72042794="">
                            <div class="overview-box card" data-v-72042794="">
                                <div class="overview-box-value" data-v-72042794="">{{ cargos }}</div>
                                <div class="overview-box-title" data-v-72042794="">Cargos</div>
                                <img src="layout/images/dashboard/tela-inicial/graph-cargos.svg" alt="roma" data-v-72042794="" />
                            </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-3" data-v-72042794="">
                            <div class="overview-box card" data-v-72042794="">
                                <div class="overview-box-value" data-v-72042794="">{{ hierarquia }}</div>
                                <div class="overview-box-title" data-v-72042794="">Hierarquia</div>
                                <img src="layout/images/dashboard/tela-inicial/graph-hierarquia.svg" alt="roma" data-v-72042794="" />
                            </div>
                        </div>

                        <div class="col-12 md:col-6 lg:col-3" data-v-72042794="">
                            <div class="overview-box card" data-v-72042794="">
                                <div class="overview-box-value" data-v-72042794="">{{ prestadorClientes }}</div>
                                <div class="overview-box-title" data-v-72042794="">Prestadores</div>
                                <img src="layout/images/dashboard/tela-inicial/graph-prestadores.svg" alt="roma" data-v-72042794="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getClientBase } from '../../services/http';
import * as storage from '../../common/storage';
import StatusAgendamento from '../../enums/StatusAgendamento';
import CarrocelNovidade from './CarrocelNovidade.vue';

export default {
    components: {
        CarrocelNovidade
    },
    emits: ['filtroStatus'],
    data() {
        return {
            profile: {},
            service: null,
            clientes: null,
            funcionarios: null,
            unidades: null,
            setores: null,
            cargos: null,
            hierarquia: null,
            prestadorClientes: null,
            usuariosCliente: null,
            agendamentosEmAberto: null,
            agendamentosConcluidos: null,
            agendamentosPendentesCliente: null,
            enumStatusAgendamento: StatusAgendamento,
            agendamentosStatus: [],
            labelsFaixaEtaria: [],
            dataFaixaEtaria: [],
            chartAgendamentos: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [],
                        hoverBackgroundColor: []
                    }
                ]
            },
            chartSexo: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [],
                        hoverBackgroundColor: []
                    }
                ]
            },
            chartSituacao: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [],
                        hoverBackgroundColor: []
                    }
                ]
            },
            chartFaixaEtaria: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [],
                        hoverBackgroundColor: []
                    }
                ]
            }
        };
    },

    async mounted() {
        this.profile = storage.getProfile();
        this.service = new BaseService('/painel-indicadores');
        await this.getTotalGeral();

        const labelsAgendamentos = this.agendamentosStatus.map((d) => d.labels).reverse();
        const dataAgendamento = this.agendamentosStatus.map((d) => +d.data).reverse();
        const colorAgendamento = this.agendamentosStatus.map((d) => d.cor).reverse();
        this.chartAgendamentos = {
            labels: labelsAgendamentos,
            datasets: [
                {
                    label: 'Agendamentos',
                    data: dataAgendamento,
                    backgroundColor: colorAgendamento
                }
            ]
        };

        const labelsSexo = this.funcionarioSexo.map((d) => d.sexo).reverse();
        const dataSexo = this.funcionarioSexo.map((d) => +d.quant).reverse();
        this.chartSexo = {
            labels: labelsSexo,
            datasets: [
                {
                    data: dataSexo,
                    backgroundColor: ['#CB2E69', '#2EA3CB']
                }
            ]
        };

        const labelsSituacao = this.funcionarioSituacao.map((d) => d.situation).reverse();
        const dataSituacao = this.funcionarioSituacao.map((d) => +d.quant).reverse();
        this.chartSituacao = {
            labels: labelsSituacao,
            datasets: [
                {
                    data: dataSituacao,
                    backgroundColor: ['#14E2B9', '#E27E14', '#46CD59', '#177667']
                }
            ]
        };

        const labelsFaixaEtaria = this.funcionarioFaixaEtaria.map((d) => d.classificacao).reverse();
        const dataFaixaEtaria = this.funcionarioFaixaEtaria.map((d) => +d.quant).reverse();
        this.chartFaixaEtaria = {
            labels: labelsFaixaEtaria,
            datasets: [
                {
                    data: dataFaixaEtaria,
                    backgroundColor: ['#99D8CE', '#50EBD2', '#4A92AF', '#177667']
                }
            ]
        };
    },

    methods: {
        async getTotalGeral() {
            const responseTotais = await getClientBase().get('/painel-indicadores');
            this.clientes = responseTotais.data.clientes;
            this.funcionarios = responseTotais.data.funcionario;
            this.unidades = responseTotais.data.unidade;
            this.setores = responseTotais.data.setores;
            this.cargos = responseTotais.data.cargos;
            this.hierarquia = responseTotais.data.hierarquia;
            this.prestadorClientes = responseTotais.data.prestadorClientes;
            this.usuariosCliente = responseTotais.data.usuariosCliente;
            this.agendamentosEmAberto = responseTotais.data.agendamentosEmAberto;
            this.agendamentosConcluidos = responseTotais.data.agendamentosConcluidos;
            this.agendamentosStatus = responseTotais.data.agendamentoStatus;
            this.funcionarioSexo = responseTotais.data.funcionarioSexo;
            this.funcionarioSituacao = responseTotais.data.funcionarioSituacao;
            this.funcionarioFaixaEtaria = responseTotais.data.funcionarioFaixaEtaria;
            this.agendamentosPendentesCliente = responseTotais.data.agendamentosPendentesCliente;
        },
        showGestaoMedica() {
            this.$router.push({ name: 'agendamentos', params: { filtroStatus: this.enumStatusAgendamento.PENDENCIA_CLIENTE } });
        }
    }
};
</script>
<style scoped>
.tamanho-card {
    height: 120px;
    width: 220px;
}

.card {
    background: #ffffff;
    padding: 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
    border-radius: 3px;
}

.centralizar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.p-ripple.purple .p-ink {
    background: #444;
}

.card-value {
    color: #444;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px;
    align-items: center;
    font-size: 25px;
    font-family: open_sansregular, Helvetica, Arial, sans-serif;
}

.card-text {
    color: #e63a78;
    padding: 10px;
    align-items: center;
    font-size: 17px;
    font-family: open_sansregular, Helvetica, Arial, sans-serif;
}

.dashboard .summary-box.summary-box-messages .card {
    border-top: 4px solid #0097a7;
    background-color: #00bcd4;
}

.dashboard .summary-box.summary-box-messages .card {
    border-top: 4px solid #0097a7;
    background-color: #00bcd4;
}

.dashboard .summary-box .card {
    min-height: 100px;
}

.dashboard .card {
    height: 100%;
    margin-bottom: 0;
}
</style>
